<template>
    <div>
        <div class="setting_content">
            <div class="" style="padding: 20px">
                <el-tabs v-model="id" @tab-click="handleClick">
                  <el-tab-pane :label="item.enterpriseName" v-for="(item,index) in tableData" :key="index" :name="item.enterpriseId"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="setting_content_content" v-if="isShow">
                <!-- 基本信息 -->
                <BasicInformation ref="basic"></BasicInformation>
                <div class="line"></div>
                <CameraList1 ref="cameraList1"></CameraList1>
                <div class="line"></div>
                <CameraList2 ref="cameraList2"></CameraList2>
                <div class="line"></div>
                <IotMeteorologicalList ref="iotMeteorologicalList"></IotMeteorologicalList>
                <div class="line"></div>
                <IotSoilList ref="iotSoilList"></IotSoilList>
               <!-- <YearsTableLsit ref="basic1"></YearsTableLsit> -->
               <div class="line"></div>
               <PersonnelTableLsit ref="basic2"></PersonnelTableLsit>
               <div class="line"></div>
               <AgriculturalMachineryTableLsit ref="basic3"></AgriculturalMachineryTableLsit>
<!--                &lt;!&ndash; <div class="line"></div>-->
<!--                <PlotTableLsit ref="basic4"></PlotTableLsit> &ndash;&gt;-->
               <!-- <div class="line"></div>
               <TaskprogressTableLsit ref="basic5"></TaskprogressTableLsit>
               <div class="line"></div>
               <WarningTableLsit ref="basic6"></WarningTableLsit> -->
            </div>
        </div>
    </div>
</template>

<script>
import BasicInformation from './components/dataScreen5_potato_DDZY/basicInformation.vue'
import CameraList1 from './components/dataScreen5_potato_DDZY/cameraList1.vue'
import CameraList2 from './components/dataScreen5_potato_DDZY/cameraList2.vue'
import IotMeteorologicalList from './components/dataScreen5_potato_DDZY/iotMeteorologicalList.vue'
import IotSoilList from './components/dataScreen5_potato_DDZY/iotSoilList.vue'

import YearsTableLsit from './components/dataScreen5_potato_DDZY/yearsTableLsit.vue'
import PersonnelTableLsit from './components/dataScreen5_potato_DDZY/personnelTableLsit.vue'
import AgriculturalMachineryTableLsit from './components/dataScreen5_potato_DDZY/agriculturalMachineryTableLsit.vue'
import PlotTableLsit from './components/dataScreen5_potato_DDZY/plotTableLsit.vue'
import TaskprogressTableLsit from './components/dataScreen5_potato_DDZY/taskprogressTableLsit.vue'
import WarningTableLsit from './components/dataScreen5_potato_DDZY/warningTableLsit.vue'
export default {
    name: '',
    data(){
        return{
          tableData: [],
          activeName: null,
          id: null,
          isShow: false,
          useEnterprise: '',
        }
    },
    components: {
        BasicInformation,
        CameraList1,
        CameraList2,
        IotMeteorologicalList,
        IotSoilList,
        YearsTableLsit,
        PersonnelTableLsit,
        AgriculturalMachineryTableLsit,
        PlotTableLsit,
        TaskprogressTableLsit,
        WarningTableLsit
    },
    mounted(){
      this.init();
    },
    methods:{
        init(){
          this.qa.datascreen3EnterpriseMapInfoQueryForPageList({pageSize: 100, status: 1}).then(res => {
                if(res.respCode == '0000') {
                    if(res.data.length > 0) {
                      // this.activeName = res.data[0].enterpriseId
                      this.id = res.data[0].enterpriseId;
                      this.useEnterprise = res.data[0].enterpriseName;
                    }
                    this.tableData = res.data;
                    this.isShow = true;
                }
            })
        },
        handleClick(item, event){
          this.useEnterprise = item.label
          console.log(item);
          this.$nextTick(() => {
            this.$refs.basic.getInfo();
            this.$refs.cameraList1.getInfo();
            this.$refs.cameraList2.getInfo();
            this.$refs.iotMeteorologicalList.getInfo();
            this.$refs.iotSoilList.getInfo();
            // this.$refs.basic1.getInfo();
            this.$refs.basic2.getInfo();
            this.$refs.basic3.getInfo();
            // this.$refs.basic4.getInfo();
            // this.$refs.basic5.getInfo();
            // this.$refs.basic6.getInfo();
          })
         
          // console.log(this.activeName)
          // console.log(item)
          // console.log(event)
          // this.activeName = parseInt(item.index);
        }
    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disease-box {
    position: relative;
    
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 50;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }

  .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }
</style>