<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="190px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col>
                    <el-form-item label="标题">
                        <el-input v-model="form.serviceAreaUnit"></el-input>
                    </el-form-item>
                    <el-form-item label="数值">
                        <el-input v-model="form.serviceArea"></el-input>
                    </el-form-item>
                    <el-form-item label="描述">
                        <el-input v-model="form.serviceAreaDesc"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.serviceEnterpriseUnit"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.serviceEnterprise"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.serviceEnterpriseDesc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.iotEquipmentCountUnit"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.iotEquipmentCount"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.iotEquipmentCountDesc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="sub-title">基地大数据</div>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.jidiBigdataTotalPlantingArea"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.jidiBigdataTotalPlantingAreaDesc"></el-input>
              </el-form-item>
              <el-form-item label="单位">
                <el-input v-model="form.jidiBigdataTotalPlantingAreaUnit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.jidiBigdataMicroPotato"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.jidiBigdataMicroPotatoDesc"></el-input>
              </el-form-item>
              <el-form-item label="单位">
                <el-input v-model="form.jidiBigdataMicroPotatoUnit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.jidiBigdataDetoxificationGroup"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.jidiBigdataDetoxificationGroupDesc"></el-input>
              </el-form-item>
              <el-form-item label="单位">
                <el-input v-model="form.jidiBigdataDetoxificationGroupUnit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.jidiBigdataFullSunlight"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.jidiBigdataFullSunlightDesc"></el-input>
              </el-form-item>
              <el-form-item label="单位">
                <el-input v-model="form.jidiBigdataFullSunlightUnit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.jidiBigdataStorageOfPotato"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.jidiBigdataStorageOfPotatoDesc"></el-input>
              </el-form-item>
              <el-form-item label="单位">
                <el-input v-model="form.jidiBigdataStorageOfPotatoUnit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.jidiBigdataOriginalSeedPotato"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.jidiBigdataOriginalSeedPotatoDesc"></el-input>
              </el-form-item>
              <el-form-item label="单位">
                <el-input v-model="form.jidiBigdataOriginalSeedPotatoUnit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
<!--          <div class="sub-title">基地育种能力</div>-->
<!--            <el-row>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="名称">-->
<!--                        <el-input v-model="form.abilityBaseAttr1"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="数量">-->
<!--                        <el-input v-model="form.abilityBaseValue1"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="单位">-->
<!--                        <el-input v-model="form.abilityBaseUnit1"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <el-row>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="名称">-->
<!--                        <el-input v-model="form.abilityBaseAttr2"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="数量">-->
<!--                        <el-input v-model="form.abilityBaseValue2"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="单位">-->
<!--                        <el-input v-model="form.abilityBaseUnit2"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <el-row>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="名称">-->
<!--                        <el-input v-model="form.abilityBaseAttr3"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="数量">-->
<!--                        <el-input v-model="form.abilityBaseValue3"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="单位">-->
<!--                        <el-input v-model="form.abilityBaseUnit3"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <el-row>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="名称">-->
<!--                        <el-input v-model="form.abilityBaseAttr4"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="数量">-->
<!--                        <el-input v-model="form.abilityBaseValue4"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-form-item label="单位">-->
<!--                        <el-input v-model="form.abilityBaseUnit4"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--            </el-row>-->

          <div class="sub-title">基地活动资讯</div>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.consult1"></el-input>
              </el-form-item>
              <el-form-item label="图片链接">
                <el-upload
                  class="avatar-uploader"
                  action="auth-web/uploadFileOSS"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess1"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.consult1Url" :src="form.consult1Url" class="avatar">
                  <div v-else class="ta_c">
                      <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                      <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                      <div class="ta_c hint"> RGB模式，不超过10M</div>
                  </div>
                </el-upload>
                <!--<el-input v-model="form.consult1Url"></el-input>-->
              </el-form-item>
              <el-form-item label="日期">
                <el-input v-model="form.consult1Time"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.consult2"></el-input>
              </el-form-item>
              <el-form-item label="图片链接">
                <el-upload
                  class="avatar-uploader"
                  action="auth-web/uploadFileOSS"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.consult2Url" :src="form.consult2Url" class="avatar">
                  <div v-else class="ta_c">
                      <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                      <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                      <div class="ta_c hint"> RGB模式，不超过10M</div>
                  </div>
                </el-upload>
                <!--<el-input v-model="form.consult2Url"></el-input>-->
              </el-form-item>
              <el-form-item label="日期">
                <el-input v-model="form.consult2Time"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
            <!-- <el-row>
                <div class="sub-title">基地实时画面</div>
            </el-row> -->
            <!-- <el-row>
                <el-form-item label="实时画面名称1">
                    <el-input v-model="form.jidiBigVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址1">
                    <el-input style="width: 550px" v-model="form.jidiBigVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称2">
                    <el-input v-model="form.jidiBigVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址2">
                    <el-input style="width: 550px" v-model="form.jidiBigVedio2Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称3">
                    <el-input v-model="form.jidiBigVedio3Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址3">
                    <el-input style="width: 550px" v-model="form.jidiBigVedio3Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称4">
                    <el-input v-model="form.jidiBigVedio4Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址4">
                    <el-input style="width: 550px" v-model="form.jidiBigVedio4Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="小号画面名称1">
                    <el-input v-model="form.jidiSmallVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="小号画面地址1">
                    <el-input style="width: 550px" v-model="form.jidiSmallVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="小号画面名称2">
                    <el-input v-model="form.jidiSmallVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="小号画面地址2">
                    <el-input style="width: 550px" v-model="form.jidiSmallVedio2Url"></el-input>
                </el-form-item>
            </el-row> -->
        </el-form>
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>

    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
                mp4: ''
            }
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.datascreen5LoadDataBO({datascreen3EnterpriseId: this.$parent.id}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        handleAvatarSuccess1(res, file) {
          console.log(res)
          this.form.consult1Url = res.data.url
        },
        handleAvatarSuccess2(res, file) {
          console.log(res)
          this.form.consult2Url = res.data.url
        },
        beforeAvatarUpload(file) {
          const isJPG = file.type === 'image/jpeg';
          const isLt2M = file.size / 1024 / 1024 < 10;

          // if (!isJPG) {
          //   this.$message.error('上传头像图片只能是 JPG 格式!');
          // }
          if (!isLt2M) {
              this.$message.error('上传头像图片大小不能超过 10MB!');
          }
          return  isLt2M;
        },
        onSaveInfo() {
            this.form.datascreen3EnterpriseId = this.$parent.id;
            this.qa.datascreen5DoSave(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.form.mp4 = res.data.url
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
</style>